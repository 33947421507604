pre[class*="language-"] {
    &:before, &:after {
        display: none !important;
    }

    code {
        border-left: 6px solid var(--surface-border) !important;
        box-shadow: none !important;
        background: var(--surface-ground) !important;
        margin: 1em 0;
        color: var(--text-color);
        font-size: 14px;
    }
}

i:not([class~="pi"]) {
    background-color: transparent;
    color: #2196f3;
    font-family: Monaco, courier, monospace;
    font-style: normal;
    font-size: 12px;
    font-weight: 500;
    padding: 0 4px;
    letter-spacing: .5px;
    font-weight: 600;
    margin: 0 2px;
    display: inline-flex;
}
